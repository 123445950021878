<template>
  <v-card class="mx-auto" max-width="433" width="400" max-height="450">
    <v-card-title class="accent  py-4 text-center white--text"
      ><v-icon class="text-center white--text py-1">{{ icon }}</v-icon>
      {{ systemType.name }}
      <v-spacer></v-spacer
      ><v-icon class="text-center white--text py-1"
        >`mdi-collapse-all-outline`</v-icon
      >{{ systemType.count }}</v-card-title
    >
    <v-dialog v-model="dialog" width="545" persistent>
      <v-card>
        <v-card-title class="headline info white--text text-center">
          {{ systemObj.name }}
        </v-card-title>
        <v-tabs
          background-color="deep-cyan accent-4"
          center
          dark
          show-arrows
          v-model="tab"
        >
          <v-tab href="#integration_count">Integrations</v-tab>
          <v-tab href="#event_count">Events</v-tab>
          <v-tab href="#errors_count">Errors</v-tab>
          <!-- <v-tab href="#tab-4">Item Frour</v-tab> -->
        </v-tabs>
        <v-card-text>
          <v-tabs-items v-model="tab">
            <v-tab-item class="my-1" value="integration_count">
              <v-card flat>
                <div class="span-wrapper">
                  <span class="tab-span">
                    <v-icon class="py-2">mdi-collapse-all-outline</v-icon>
                    count:
                    {{ systemObj.integration_count || 0 }}
                  </span>
                  <span class="tab-span">
                    <v-icon class="py-2">mdi-motion-pause</v-icon> paused:
                    {{ systemObj.integration_paused_count || 0 }}
                  </span>
                  <span class="tab-span">
                    <v-icon class="py-2">mdi-alert-octagon</v-icon>
                    errors:
                    {{ systemObj.integration_error_count || 0 }}
                  </span>
                </div>
                <v-btn
                  depressed
                  small
                  width="100%"
                  color="info"
                  :to="{
                    name: 'systemIntegrations',
                    params: {
                      system_id: systemObj._id,
                      systemName: systemObj.name,
                      systemType: systemObj.system_type,
                      systemTypeName: systemObj.system_type_name
                    }
                  }"
                >
                  to integrations

                  <v-icon color="orange darken-2" right>
                    mdi-open-in-new
                  </v-icon>
                </v-btn>
              </v-card>
            </v-tab-item>
            <v-tab-item value="event_count">
              <v-card flat>
                <div class="span-wrapper">
                  <span class="tab-span">
                    <v-icon class="py-2">mdi-collapse-all-outline</v-icon>
                    count:
                    {{ systemObj.events_count || 0 }}
                  </span>
                  <span class="tab-span">
                    <v-icon class="py-2">mdi-download-off-outline</v-icon>
                    finish:
                    {{ systemObj.finish_events_count || 0 }}
                  </span>
                  <span class="tab-span">
                    <v-icon class="py-2">mdi-download-box</v-icon>
                    executable:
                    {{ systemObj.executable_events_count || 0 }}
                  </span>
                </div>
                <v-btn
                  depressed
                  small
                  width="100%"
                  color="info"
                  :to="{
                    name: 'systemEvents',
                    params: {
                      system_id: systemObj._id,
                      systemName: systemObj.name
                    }
                  }"
                >
                  to events

                  <v-icon color="orange darken-2" right>
                    mdi-open-in-new
                  </v-icon>
                </v-btn>
              </v-card>
            </v-tab-item>
            <v-tab-item value="errors_count">
              <v-card flat>
                <div class="span-wrapper">
                  <span class="tab-span">
                    <v-icon class="py-2">mdi-collapse-all-outline</v-icon>
                    count:
                    {{ systemObj.errors_count || 0 }}
                  </span>
                  <span class="tab-span">
                    <v-icon class="py-2">mdi-account-remove</v-icon>
                    auth errors:
                    {{ systemObj.auth_errors_count || 0 }}
                  </span>
                </div>
                <v-btn
                  depressed
                  small
                  width="100%"
                  color="info"
                  :to="{
                    name: 'systemErrors',
                    params: {
                      system_id: systemObj._id,
                      systemName: systemObj.name
                    }
                  }"
                >
                  to errors

                  <v-icon color="orange darken-2" right>
                    mdi-open-in-new
                  </v-icon>
                </v-btn>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="cyan lighten-1"
            dark
            depressed
            :to="{ name: 'SystemPage', params: { system_id: systemObj._id } }"
          >
            <v-icon>mdi-cog-sync</v-icon>
            to system
          </v-btn>
          <v-btn
            v-if="isAdmin"
            color="amber lighten-1"
            dark
            depressed
            @click="dialog = false"
            :href="systemObj.url"
            target="_blank"
          >
            <v-icon>mdi-eye-settings-outline</v-icon>
            to integration admin
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            close <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-text class="card-text">
      <v-list-item v-for="system in systemType.systems" :key="system.name" link>
        <v-list-item-avatar>
          <v-icon class="teal lighten-1 white--text">
            mdi-cog
          </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ system.name }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <template>
            <v-btn
              icon
              color="red lighten-2"
              dark
              @click="getSystem(`${system.system_id}`)"
            >
              <v-icon color="teal lighten-1">
                mdi-information
              </v-icon>
            </v-btn>
          </template>
        </v-list-item-action>
      </v-list-item>
    </v-card-text>
    <v-spacer></v-spacer>
    <div class="wrapper text-center">
      <v-btn
        color="accent darken-1"
        dark
        width="100%"
        :to="{
          name: 'systemsByType',
          params: { system_type: systemType.type, TypeName: systemType.name }
        }"
      >
        <v-icon class="icon">mdi-folder-information-outline</v-icon>
        Jump to systems
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import SystemService from '@/services/SystemService.js'
import store from '@/store/index'

function _getIcon(type) {
  let icons = {
    adv: 'mdi-bullhorn-outline',
    appeal: 'mdi-comment-alert-outline',
    email: 'mdi-email-outline'
  }
  return icons[type]
}

export default {
  props: {
    systemType: Object
  },
  data() {
    return {
      dialog: false,
      tab: null,
      systemObj: {}
    }
  },
  methods: {
    getSystem(system_id) {
      SystemService.getSystemForMainPage(system_id)
        .then(response => {
          this.systemObj = response.data
          this.$store.dispatch('systems/setSystem', {
            systemId: this.systemObj._id,
            systemName: this.systemObj.name,
            systemType: this.systemObj.system_type,
            systemTypeName: this.systemObj.system_type_name,
            systemUrl: this.systemObj.url
          })
          this.dialog = true
        })
        .catch(error => {
          this.$store.dispatch('setLoader', false)
          console.log(error.response)
        })
    }
  },
  computed: {
    icon() {
      return _getIcon(this.systemType.type)
    },
    isAdmin() {
      return store.getters['auth/isAdmin']
    }
  }
}
</script>

<style scoped>
.icon {
  margin-right: 5px;
}
.span-wrapper {
  margin-left: 80px;
}
.tab-span {
  margin-right: 25px;
}
.button {
  float: right;
  vertical-align: bottom;
}
.card-text {
  padding: 0;
}
html {
  overflow: hidden !important;
}

.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
.wrapper {
  margin: 10px
}
</style>
