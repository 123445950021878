import Vue from 'vue'
import Vuex from 'vuex'
import * as auth from '@/store/modules/auth.js'
import * as systems from '@/store/modules/systems.js'
import * as event from '@/store/modules/event.js'
import * as breadcrumbs from '@/store/modules/breadcrumbs.js'
import * as error from '@/store/modules/error.js'
import * as notification from '@/store/modules/notification.js'
import Config from "@/config.json"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseURL: process.env.VUE_APP_BASE_URL || Config.BACKEND_BASE_URL,
    // baseURL: 'http://localhost:8000/api',
    loader: false
  },
  mutations: {
    LOADER(state, payload) {
      state.loader = payload
    }
  },  
  actions: {
    setLoader({ commit }, payload) {
      commit('LOADER', payload)
    }
  },
  modules: { auth, systems, event, breadcrumbs, error, notification }
})
