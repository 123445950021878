import AuthService from '@/services/AuthService.js'

export const namespaced = true

function checkIsAdmin() {
  status = localStorage.getItem('is_admin')
  if (status === 'true') {
    return true
  } return false
}

export const state = {
  username: localStorage.getItem('username') || '',
  user_id: localStorage.getItem('user_id') || '',
  is_admin: checkIsAdmin(),
  auth: JSON.parse(localStorage.getItem('auth'))
    ? localStorage.getItem('auth')
    : null,
  accessToken: localStorage.getItem('accessToken') || '',
  refreshToken: localStorage.getItem('accessToken') || ''
}

export const mutations = {
  SET_AUTH_DATA(state, authData) {
    state.auth = authData
    state.accessToken = authData['access_token']
    state.username = authData['username']
    state.user_id = authData['user_id']
    state.is_admin = authData['is_admin']
    localStorage.setItem('auth', JSON.stringify(authData))
    localStorage.setItem('accessToken', authData['access_token'])
    localStorage.setItem('is_admin', authData['is_admin'])
    localStorage.setItem('username', authData['username'])
    localStorage.setItem('user_id', authData['user_id'])
    localStorage.setItem('refreshToken', authData['refresh_token'])
  },
  REMOVE_AUTH_DATA(state) {
    state.auth = null
    state.user = null
    state.accessToken = ''
    state.refreshToken = ''
    state.user_id = ''
    state.username = ''
    state.is_admin = false
    localStorage.removeItem('auth')
    localStorage.removeItem('is_admin')
    localStorage.removeItem('username')
    localStorage.removeItem('user_id')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
  }
}

export const actions = {
  login({ commit }, credentials) {
    return AuthService.login(credentials).then(({ data }) => {
      commit('SET_AUTH_DATA', data)
    })
  },
  logout({ commit }) {
    return commit('REMOVE_AUTH_DATA')
  }
}

export const getters = {
  loggedIn(state) {
    return !!state.auth
  },
  getUserName(state) {
    return state.username
  },
  getUserId(state) {
    return state.user_id
  },
  isAdmin(state) {
    return state.is_admin
  },
  getAuthKey(state) {
    if (!!state.accessToken) {
      return state.accessToken
    }
    return ''
  }
}
