import apiClient from '@/services/ApiClient.js'
import store from '@/store/index'

export default {
  login(credentials) {
    return apiClient.post(store.state['baseURL'] + '/auth/token/', credentials, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
  },
  getMe(token) {
    return apiClient.get('/auth/me/', {
      headers: { Authorization: 'Bearer ' + token }
    })
  }
}
