export const namespaced = true

export const state = {
  // array of breadcrumb objects, in the form of VueRouter
  // descriptors (see https://router.vuejs.org/api/#to)
  breadcrumbs: []
}

export const mutations = {
  SET(state, breadcrumbs) {
    state.breadcrumbs = breadcrumbs
  },
  PUSH(state, breadcrumb) {
    state.breadcrumbs.push(breadcrumb)
  },
  POP(state) {
    state.breadcrumbs.pop()
  },
  REPLACE(state, payload) {
    const index = state.breadcrumbs.findIndex(breadcrumb => {
      return breadcrumb.text === payload.find
    })

    if (index) {
      state.breadcrumbs.splice(index, 1, payload.replace)
    }
  },
  EMPTY(state) {
    state.breadcrumbs = []
  }
}
