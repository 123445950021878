import SystemService from '@/services/SystemService.js'

export const namespaced = true

export const state = {
  systemTypes: [],
  systems: [],
  system: localStorage.getItem('system')
    ? JSON.parse(localStorage.getItem('system'))
    : {}
}

export const mutations = {
  SET_SYSTEM_TYPE(state, typesData) {
    state.systemTypes = typesData
  },
  SET_SYSTEMS(state, systems) {
    state.systems = systems
  },
  SET_SYSTEM(state, system) {
    state.system = system
  }
}

export const actions = {
  getSystemTypes({ commit }, systemType) {
    return SystemService.getSystemTypes().then(({ data }) => {
      commit('SET_SYSTEM_TYPE', data)
    })
  },
  setSystem({ commit }, system) {
    commit('SET_SYSTEM', system)
    localStorage.setItem('system', JSON.stringify(system))
  }
}

export const getters = {
  getSystemType: state => type => {
    return state.systemTypes.find(systemType => systemType.type === type)
  }
}
