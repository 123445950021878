// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

const vuetify = new Vuetify({
    theme: {
      themes: {
        light: {
        //   primary: '#3f51b5',
        //   secondary: '#b0bec5',
          accent: '#60C042',
        //   error: '#b71c1c',
        },
      },
    },
  })

export default vuetify