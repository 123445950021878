<template>
  <v-app>
    <v-app-bar app color="white darken-1">
      <v-toolbar-title>
        <v-btn color="white" clas href="/">
          <v-img
          max-height="60"
          max-width="60"
          src="logo.png"
        >
        </v-img>
        </v-btn>
      </v-toolbar-title>
      <v-divider class="mx-4" vertical></v-divider>
      <div v-if="isAdmin" class="wrapper">
        <v-btn
          v-if="errorsCount > 0"
          rounded
          dark
          :to="{ name: 'Error' }"
          class="red darken-2 info-btn"
          ><v-icon size="20px" class="icons">{{
            icons.mdiAlertOctagonOutline
          }}</v-icon>
          <span class="span-info">errors ({{ errorsCount }})</span>
        </v-btn>
        <v-btn
          v-else
          rounded
          dark
          :to="{ name: 'Error' }"
          class="cyan info-btn"
          ><v-icon size="20px" class="icons">{{
            icons.mdiAlertOctagonOutline
          }}</v-icon>
          <span class="span-info">errors ({{ errorsCount }})</span>
        </v-btn>
        <v-btn rounded dark color="teal lighten-1 info-btn" :to="{ name: 'Admin' }"
          ><v-icon>mdi-account-cog</v-icon>
          <span class="span-info">Users</span></v-btn
        >
        <v-btn rounded dark color="blue lighten-1 info-btn" :to="{ name: 'Settings' }"
          ><v-icon>mdi-cog</v-icon>
          <span class="span-info">Settings</span></v-btn
        >
      </div>

      <v-spacer></v-spacer>
      <div v-if="isLoggedIn">
          <v-btn text rounded :to="{ name: 'Operations'}"
          ><v-icon size="20px" class="icons">{{ icons.mdiAccount }}</v-icon>
          Operations:
          {{ username }}
        </v-btn>
        <v-btn
          v-for="link in links"
          :key="`${link.label}-header-link`"
          text
          rounded
          :to="link.url"
          ><v-icon size="20px" class="icons">{{ link.icon }}</v-icon>
          {{ link.label }}</v-btn
        >
        <v-divider class="mx-4" vertical></v-divider>
        <v-btn text rounded @click="logout">Logout</v-btn>
      </div>
    </v-app-bar>
    <v-main>
      <Loader />
      <NotificationContainer />
      <router-view></router-view>
    </v-main>
    <v-footer color="accent darken-1" padless>
      <v-row v-if="isLoggedIn" justify="center" no-gutters>
        <v-btn
          text
          rounded
          class="my-2"
          color="white"
          :to="{ name: 'Operations'}"
          ><v-icon class="icons">{{ icons.mdiAccount }}</v-icon> Operations:
          {{ username }}
        </v-btn>
        <v-btn
          v-for="link in links"
          :key="`${link.url}-footer-link`"
          color="white"
          text
          rounded
          class="my-2"
          :to="link.url"
        >
          {{ link.label }}
        </v-btn>
        <v-btn
          v-if="isLoggedIn"
          color="white"
          text
          rounded
          class="my-2"
          @click="logout"
          >Logout</v-btn
        >
        <v-btn v-else color="white" text rounded class="my-2" to="/Login"
          >Login</v-btn
        >
        <v-col class="accent py-4 text-center white--text" cols="12">
          {{ new Date().getFullYear() }} — <strong>Manage Application</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import Loader from '@/components/partial/Loader'
import NotificationContainer from '@/components/NotificationContainer'
import {
  mdiAccount,
  mdiHome,
  mdiAdjust,
  mdiBullhorn,
  mdiAlertOctagonOutline
} from '@mdi/js'

function getLinks() {
  let links = [
    {
      label: 'Home',
      url: '/',
      icon: mdiHome
    }
  ]
  return links
}

export default {
  name: 'App',
  components: { Loader, NotificationContainer },
  data: () => ({
    showPassword: false,
    // username: 'Admin',
    icons: {
      mdiAccount,
      mdiHome,
      mdiAdjust,
      mdiBullhorn,
      mdiAlertOctagonOutline
    },
    // errorsCount: 0,
    polling: null
  }),
  computed: {
    links() {
      return getLinks()
    },
    isLoggedIn() {
      return this.$store.getters['auth/loggedIn']
    },
    isAdmin() {
      return this.$store.getters['auth/isAdmin']
    },
    username() {
      return this.$store.getters['auth/getUserName']
    },
    errorsCount() {
      return this.$store.getters['error/getTotalErrors']
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push({ name: 'Login' }, () => {})
      })
    },
    pollData() {
      // this.$store.dispatch('error/getTotalNotCheckedErrors')
      this.polling = setInterval(() => {
        if (this.isAdmin) {
          this.$store.dispatch('error/getTotalNotCheckedErrors')
        }
        
      }, 30000)
    }
  },
  created() {
    this.pollData()
  }
}
</script>

<style>
body {
  min-width: 844px !important;
}
.icons {
  margin-right: 3px;
  margin-bottom: 3px;
}
.v-toolbar__content {
  min-width: 844px !important;
}
@media (max-width: 1110px) {
  .info-btn {
    width: 30px !important;
    min-width: 30px !important;
    height: 30px !important;
    font-size: 15px !important;
  }
  .span-info {
    display: none;
    min-width: 30px;
    font-size: 15px !important;
  }
}
</style>
