import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      hideToAuth: true
    }
  },
  {
    path: '/operations',
    name: 'Operations',
    component: () => import('../views/Operation.vue'),
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/errors',
    name: 'Error',
    component: () => import('../views/Error.vue'),
    meta: {
      requiresAuth: true,
      onlyAdmin: true
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings.vue'),
    meta: {
      requiresAuth: true,
      onlyAdmin: true
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin.vue'),
    meta: {
      requiresAuth: true,
      onlyAdmin: true
    }
  },
  {
    path: '/:system_id/',
    name: 'SystemPage',
    props: true,
    component: () => import('../views/System.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:system_id/events',
    name: 'systemEvents',
    props: true,
    component: () => import('../views/SystemEvents.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:system_id/errors',
    name: 'systemErrors',
    props: true,
    component: () => import('../views/SystemErrors.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:system_id/integrations',
    name: 'systemIntegrations',
    props: true,
    component: () => import('../views/SystemIntegrations.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:system_type/systems',
    name: 'systemsByType',
    props: true,
    component: () => import('../views/ByTypeSystems.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch('setLoader', true)
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['auth/loggedIn']) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
  if (to.matched.some(record => record.meta.hideToAuth)) {
    if (!store.getters['auth/loggedIn']) {
      next()
      return
    }
    next('/')
  } else {
    next()
  }
})

router.afterEach(() => {
  store.dispatch('setLoader', false)
})

export default router
