import apiClient from '@/services/ApiClient.js'
import longPollingAPIClient from '@/services/LongPollingApiClient.js'

export default {
  getAggregateErrorsBySytemIdDates(system_id) {
    return apiClient.get(`errors/graph/dates/?system_id=${system_id}`)
  },
  getAggregateErrorsBySytemIdIntgrations(system_id) {
    return apiClient.get(`errors/graph/integrations/?system_id=${system_id}`)
  },
  getNotCheckedErrors() {
    return longPollingAPIClient.get('errors/count/?deleted=false&checked=false')
  },
  getErrors(systemId, limit, offset, sortBy, sort, type, search) {
    let url =
      '/errors/?limit=' +
      limit +
      '&offset=' +
      offset +
      '&sort_by=' +
      sortBy +
      '&sort=' +
      sort
    if (type == 'checked') {
      url += '&checked=true'
    } else if (type == 'not_checked') {
      url += '&checked=false'
    }
    if (search !== undefined && search.length >= 4) {
      url += `&search=${search}`
    }
    if (systemId !== undefined) {
      url += `&system_id=${systemId}`
    }
    return apiClient.get(url)
  },
  deleteError(errorId) {
    return apiClient.delete('/errors/' + errorId + '/')
  },
  checkCheckedStatus(errorId, status) {
    return apiClient.put('/errors/' + errorId + '/?checked=' + status)
  }
}
