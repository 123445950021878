import EventService from '@/services/EventService.js'

export const namespaced = true

export const state = {
  events: [],
  totalEvents: 0,
  nextPage: '',
  prevPage: ''
}

export const mutations = {
  SET_EVENTS(state, events) {
    state.events = events
  },
  SET_TOTAL_EVENTS(state, total) {
    state.totalEvents = total
  },
  SET_NEXT_PAGE(state, nextPage) {
    state.nextPage = nextPage
  },
  SET_PREV_PAGE(state, prevPage) {
    state.prevPage = prevPage
  }
}

export const actions = {
  fetchEvents(
    { commit, dispatch, state },
    { systemId, limit, offset, sort_by, sort, type }
  ) {
    EventService.getEvents(systemId, limit, offset, sort_by, sort, type)
      .then(response => {
        // console.log(response.headers['x-total-count'])
        commit('SET_TOTAL_EVENTS', response.data.count)
        commit('SET_NEXT_PAGE', response.data.next)
        commit('SET_PREV_PAGE', response.data.prev)
        commit('SET_EVENTS', response.data.results)
        return {
          items: response.data.results,
          totalEvents: response.data.count
        }
      })
      .catch(error => {
        console.log('events - ', error.response)
        dispatch('setLoader', false)
        // const notification = {
        //   type: 'error',
        //   message: 'Fetch events error, detail: ' + error.message
        // }
        // dispatch('notification/add', notification, { root: true })
      })
  }
}
