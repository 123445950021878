<template>
  <div class="home">
    <v-container mx-6>
      <v-row flex>
        <v-col
          v-for="systemType in systems.systemTypes"
          :key="systemType.type"
          class="flexes"
        >
          <SystemTypeCard :systemType="systemType" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SystemTypeCard from '@/components/SystemTypeCard.vue'
import { mapState } from 'vuex'
import store from '@/store/index'

export default {
  components: {
    SystemTypeCard
  },
  name: 'Home',
  beforeRouteEnter(routeTo, routeFrom, next) {
    store
      .dispatch('systems/getSystemTypes')
      .then(() => {
        next()
      })
      .catch(error => {
        store.dispatch('setLoader', false)
        console.log(error.response)
      })
  },
  computed: {
    ...mapState(['systems'])
  }
}
</script>

<style scoped>
.flexes {
  flex-wrap: wrap;
  display: flex;
}
</style>
