import ErrorService from '@/services/ErrorService.js'

export const namespaced = true

export const state = {
  totalNotCheckedErrors: localStorage.getItem('totalNotCheckedErrors') || 0
}

export const mutations = {
  SET_TOTAL_NOT_CHECKED_ERRORS(state, total) {
    state.totalNotCheckedErrors = total
    localStorage.setItem('totalNotCheckedErrors', total)
  }
}

export const actions = {
  getTotalNotCheckedErrors({ commit, dispatch }) {
    return ErrorService.getNotCheckedErrors()
      .then(response => {
        commit('SET_TOTAL_NOT_CHECKED_ERRORS', response.data.count)
      })
      .catch(error => {
        dispatch('setLoader', false)
        const notification = {
          type: 'error',
          message: 'Fetch events error, detail: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
      })
  }
}

export const getters = {
  getTotalErrors: state => {
    return state.totalNotCheckedErrors
  }
}
