import axios from 'axios'
import store from '@/store/index'
import Config from "@/config.json"

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL || Config.BACKEND_BASE_URL,
    // baseURL: 'http://localhost:8000/api',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 10000
})

apiClient.interceptors.request.use(function(config) {
  store.dispatch('setLoader', true)
  let authKey = store.getters['auth/getAuthKey']
  config.headers['Authorization'] = 'Bearer ' + authKey
  return config
})

apiClient.interceptors.response.use(function(config) {
  store.dispatch('setLoader', false)
  return config
})

export default apiClient
