import apiClient from '@/services/ApiClient.js'

export default {
  getEvents(systemId, limit, offset, sortBy, sort, type, search) {
    let url =
      '/events/?limit=' +
      limit +
      '&offset=' +
      offset +
      '&system_id=' +
      systemId +
      '&sort_by=' +
      sortBy +
      '&sort=' +
      sort
    if (type == 'finished') {
      url += '&is_finish=true'
    } else if (type == 'executable') {
      url += '&is_finish=false'
    }
    if (search !== undefined && search.length >= 4) {
      url += `&search=${search}`
    }
    return apiClient.get(url)
  },
  deleteEvent(eventId) {
    return apiClient.delete('/events/' + eventId + '/')
  }
}
