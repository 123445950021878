import apiClient from '@/services/ApiClient.js'

export default {
  getSystemTypes() {
    return apiClient.get('/systems/types/')
  },
  getSystemsByType(type, limit, offset, sortBy, sort, search) {
    let url = `/systems/by_type/?system_type=${type}&limit=${limit}&offset=${offset}&sort_by=${sortBy}&sort=${sort}`
    if (search !== undefined && search.length >= 4) {
      url += `&search=${search}`
    }
    return apiClient.get(url)
  },
  getSystemForMainPage(systemId) {
    return apiClient.get(`/systems/foreign_collections/${systemId}/`)
  },
  getSystem(systemId) {
    return apiClient.get(`/systems/${systemId}/`)
  },
  updateSystem(systemId, data) {
    return apiClient.put(`/systems/${systemId}/`, data)
  },
  addSystem(data) {
    return apiClient.post(`/systems/`, data)
  },
  deleteSystem(systemId) {
    return apiClient.delete(`/systems/${systemId}/`)
  }
}
